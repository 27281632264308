import '@total-typescript/ts-reset'

import { ReactNode, useCallback, useEffect, useState } from 'react'
import Client, { Cart } from 'shopify-buy'

import { Analytics } from '~/api/analytics'
import { GTM_EVENTS } from '~/constants'
import Context, { StoreContextType } from '../context/StoreContext'
import { useCartModal } from '../context/UIContext'

const OPTIONS = {
  storefrontAccessToken: process.env.NEXT_PUBLIC_SHOPIFY_ACCESS_TOKEN as string,
  domain: `${process.env.NEXT_PUBLIC_SHOP_NAME}.myshopify.com`,
  apiVersion: process.env.NEXT_PUBLIC_SHOPIFY_API_VERSION as string,
}

const client = Client.buildClient(OPTIONS)

type Store = Pick<
  StoreContextType,
  | 'client'
  | 'cart'
  | 'isUpdatingLineItem'
  | 'openingCheckout'
  | 'lineItemJustAddedToCart'
  | 'isAddingMainProduct'
  | 'isAddingPairProduct'
  | 'isBuyNow'
>

const initialStoreState: Store = {
  client,
  cart: null,
  isUpdatingLineItem: null,
  openingCheckout: false,
  lineItemJustAddedToCart: null,
  isAddingMainProduct: false,
  isAddingPairProduct: false,
  isBuyNow: false,
}

const StoreProvider = ({ children }: { children: ReactNode }) => {
  const [store, updateStore] = useState<Store>(initialStoreState)

  const [chargifyDiscountCode, setChargifyDiscountCode] = useState<
    string | undefined
  >()

  const { cart } = store
  let shopifyCheckoutUrl = cart?.checkoutUrl
    ? cart?.checkoutUrl.replace(
        'https://penguincbd.myshopify.com',
        'https://checkout.penguincbd.com'
      )
    : ''

  const cartId = cart?.id

  useEffect(() => {
    const initializeCart = async () => {
      const isBrowser = typeof window !== 'undefined'
      const existingCartId = isBrowser
        ? localStorage.getItem('shopify_cart_id')
        : null

      if (existingCartId) {
        try {
          const fetchCart = await fetch(`/api/shopify/cart/get-cart`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
            },

            body: JSON.stringify({
              cartId: existingCartId,
            }),
          })
          const cartData = (await fetchCart.json()).cart as Cart

          if (isBrowser) {
            localStorage.setItem('shopify_cart_id', `${cartData.id}`)
          }

          updateStore({ ...store, cart: cartData })
          return
        } catch (e) {
          localStorage.setItem('shopify_cart_id', '')
        }
      }

      const createCart = await fetch('/api/shopify/cart/create-cart')
      const cartData = (await createCart.json()).cart as Cart
      localStorage.setItem('shopify_cart_id', `${cartData.id}`)
      updateStore({ ...store, cart: cartData })
    }

    initializeCart()
  }, [])

  const openCheckout = useCallback(async () => {
    // This applies if we're no longer using Carthook and instead Shopify's native checkout.
    window.analytics?.track(Analytics.events.Checkout, {
      checkoutUrl: shopifyCheckoutUrl,
    })
    const discountCode = localStorage.getItem('discountCode')
    if (discountCode) {
      const separator = shopifyCheckoutUrl.includes('?') ? '&' : '?'
      window.location.href = `${shopifyCheckoutUrl}${separator}discount=${encodeURIComponent(discountCode)}`
      localStorage.removeItem('discountCode')
    } else {
      window.location.href = shopifyCheckoutUrl
    }
  }, [shopifyCheckoutUrl])

  const { toggleCartIsOpen } = useCartModal()

  const addVariantToCart = useCallback(
    async ({
      variantId,
      quantity,
      sellingPlanId,
      isAddingMainProduct = false,
      isAddingPairProduct = false,
    }: {
      variantId: string
      quantity: number
      sellingPlanId?: string | null
      isAddingMainProduct?: boolean
      isAddingPairProduct?: boolean
    }) => {
      if (variantId === '' || !quantity) {
        console.error(
          '[add-variant-to-cart] Both an ID and quantity are required.'
        )
        return
      }

      updateStore((prevState) => {
        return {
          ...prevState,
          isAddingMainProduct,
          isAddingPairProduct,
        }
      })

      window.analytics?.track(Analytics.events['Add To Cart'], {
        variantId,
        quantity,
      })

      const line = sellingPlanId
        ? {
            merchandiseId: variantId,
            sellingPlanId: `gid://shopify/SellingPlan/${sellingPlanId}`,
            quantity: parseInt(`${quantity}`, 10),
          }
        : {
            merchandiseId: variantId,
            quantity: parseInt(`${quantity}`, 10),
          }

      const addLinesToCart = await fetch(`/api/shopify/cart/add-line-items`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          cartId,
          lines: [line],
        }),
      })

      const cartData = (await addLinesToCart.json()).cart as Cart

      if (!cartData) {
        console.error("Cart data is empty or invalid.");
        return;
      }

      console.log('Full cartData:', cartData)
  
      // Prepare the data for Klaviyo
      const klaviyoCart = {
        total_price: cartData.cost.totalAmount.amount || 0,
        $value: cartData.cost.totalAmount.amount || 0,
        items: cartData.lines.edges.map(edge => {
          const { quantity, merchandise } = edge.node;
          const price = merchandise?.priceV2?.amount || 0;
          const title =
            merchandise?.product?.title ||
            merchandise?.title ||
            'Unknown';
      
          return {
            id: edge.node.id,
            title,
            quantity,
            price,
          };
        })
      };
  
      console.log("Processed Klaviyo cart data:", klaviyoCart);
  
      // Send the "Added to Cart" event to Klaviyo
      if (window._learnq) {
        window._learnq.push(['track', 'Added to Cart', klaviyoCart]);
        console.log("Added to Cart event sent to Klaviyo.");
      } else {
        console.error("Klaviyo (_learnq) is not initialized.");
      }
      
      window.dataLayer?.push({
        event: GTM_EVENTS['added-cart'],
        variant: {
          variantId,
          quantity,
        },
        cartData,
      })
      updateStore({
        ...store,
        cart: cartData,
        isUpdatingLineItem: null,
        isAddingMainProduct: false,
        isAddingPairProduct: false,
      })

      toggleCartIsOpen(true)
    },
    [cartId, toggleCartIsOpen, shopifyCheckoutUrl]
  )
  const addMultipleVariantsToCart = useCallback(
    async (lineItemsToUpdate: { variantId: string; quantity: number }[]) => {
      lineItemsToUpdate.forEach(({ variantId, quantity }) => {
        window.analytics?.track(Analytics.events['Add To Cart'], {
          variantId,
          quantity,
        })
      })

      const lines = lineItemsToUpdate.map((line) => ({
        merchandiseId: line.variantId,
        quantity: line.quantity,
      }))

      const addLinesToCart = await fetch(`/api/shopify/cart/add-line-items`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          cartId,
          lines,
        }),
      })

      const cartData = (await addLinesToCart.json()).cart as Cart

      toggleCartIsOpen(true)
      updateStore({
        ...store,
        cart: cartData,
      })
    },
    [cartId, toggleCartIsOpen]
  )
  const removeLineItem = useCallback(
    async (lineItemId: string) => {
      updateStore((state) => ({ ...state, isUpdatingLineItem: lineItemId }))
      const removeLineItem = await fetch(`/api/shopify/cart/remove-line-item`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          cartId,
          lineIds: [lineItemId],
        }),
      })

      const cartData = (await removeLineItem.json()).cart as Cart

      window.analytics?.track(Analytics.events['Remove From Cart'], {
        lineItemId,
      })

      window.dataLayer?.push({
        event: GTM_EVENTS['removed-cart'],
        lineItemId,
        cartData,
      })

      updateStore({
        ...store,
        cart: cartData,
        isUpdatingLineItem: null,
      })
    },
    [cartId]
  )

  const updateLineItem = useCallback(
    async (lineItemID: string, lineMerchandiseId: string, quantity: number) => {
      if (!cartId) return console.warn('[update-line-item]: no checkoutId')

      updateStore((state) => ({
        ...state,
        isUpdatingLineItem: lineItemID,
      }))
      window.analytics?.track(Analytics.events['Update Cart Quantity'], {
        lineItemID,
        lineMerchandiseId,
        quantity,
      })

      const updateLineItem = await fetch(
        `/api/shopify/cart/update-line-items`,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            cartId,
            lines: [
              {
                id: lineItemID,
                merchandiseId: lineMerchandiseId,
                quantity: quantity,
              },
            ],
          }),
        }
      )

      const cartData = (await updateLineItem.json()).cart as Cart

      window.dataLayer?.push({
        event: GTM_EVENTS['updated-cart'],
        variant: {
          lineItemID,
          lineMerchandiseId,
          quantity,
        },
        cartData,
      })
      updateStore({
        ...store,
        cart: cartData,
        isUpdatingLineItem: null,
      })
    },
    [cartId]
  )

  const clearLineItemJustAddedToCart = useCallback(
    () =>
      updateStore((state) => {
        if (!state.lineItemJustAddedToCart) return state
        return {
          ...state,
          lineItemJustAddedToCart: null,
        }
      }),
    []
  )

  const addDiscount = useCallback(
    async (code: string) => {
      const updateDiscount = await fetch(`/api/shopify/cart/update-discount`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          cartId,
          discountCodes: [code],
        }),
      })

      const cartData = (await updateDiscount.json()).cart as Cart

      return cartData
    },
    [cartId]
  )

  const buyNowProduct = useCallback(
    async ({
      variantId,
      isBuyNow = false,
    }: {
      variantId: string
      isBuyNow?: boolean
    }) => {
      if (variantId === '') {
        console.error('[buy-now] ID is required.')
        return
      }

      updateStore((prevState) => {
        return {
          ...prevState,
          isBuyNow,
        }
      })

      const newCheckout = await fetch(`/api/shopify/buy-now`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          variantId,
        }),
      })

      const checkoutData = await newCheckout.json()

      location.href = checkoutData?.cart?.checkoutUrl.replace(
        'https://penguincbd.myshopify.com',
        'https://checkout.penguincbd.com'
      )

      updateStore({
        ...store,
        isBuyNow: false,
      })
    },
    []
  )

  return (
    <Context.Provider
      value={{
        ...store,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        openCheckout,
        clearLineItemJustAddedToCart,
        addDiscount,
        addMultipleVariantsToCart,
        chargifyDiscountCode,
        setChargifyDiscountCode,
        buyNowProduct,
      }}
    >
      {children}
    </Context.Provider>
  )
}
export default StoreProvider