import { GoogleTagManager } from '@next/third-parties/google'
import type { AppProps } from 'next/app'
import local from 'next/font/local'
import { useEffect } from 'react'
import { ComponentType } from 'react-spring'
import { ThemeUIProvider } from 'theme-ui'
import Typography from 'typography'
import { GlobalAppPropsProvider } from '~/api/GlobalAppPropsContext'
import { type withPageProps } from '~/api/services'
import CartModal from '~/components/Cart-Modal'
import { Footer } from '~/components/Footer'
import Header from '~/components/Header'
import { SmartNavDrawer } from '~/components/Nav-Drawer'
import StoreProvider from '~/context/ContextProvider'
import { UIProvider } from '~/context/UIContext'
import ThemeUi from '~/styles/theme'
import '../styles/global.css'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { SessionProvider } from 'next-auth/react'
import { Toaster } from 'react-hot-toast'
import { AccountProvider } from '~/context/accountContext'
import Script from 'next/script'
import NextTransitionBar from 'next-transition-bar'

export const sofia = local({
  src: [
    {
      path: '../font/SofiaProRegular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../font/SofiaProMedium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../font/SofiaProSemiBold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: '../font/SofiaProBold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  preload: true,
  variable: '--font-sofia',
  display: 'swap',
})

const typography = new Typography({
  baseFontSize: '18px',
  baseLineHeight: 1.666,
  headerFontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ],
  bodyFontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
  ],
})

const typographyStyle = typography.toString()

export default function App({
  Component,
  pageProps: { session, ...pageProps },
  router,
}: Omit<AppProps, 'pageProps'> & {
  pageProps: Awaited<ReturnType<typeof withPageProps<{ headerProps: {} }>>>
  Component?: {
    getLayout?: (page: React.ReactNode) => React.ReactNode
  } & ComponentType
}) {
  const {
    allShopifyProduct,
    allStampedReviewSummaries,
    footerSettings,
    headerProps,
    mainHeaderSettings,
    cartFreeShipping,
    ...restPageProps
  } = pageProps
  const nextRouter = useRouter()
  const canonicalUrl = `https://penguincbd.com${nextRouter.asPath === "/" || nextRouter.asPath.startsWith("/index") ? "" : nextRouter.asPath}`.split("?")[0];

  // useEffect(() => {
  //   document.addEventListener('smile-ui-loaded', async () => {
  //     await window.SmileUI.init({
  //       channel_key: 'channel_5kqZWF4op9ISOs0s1K92Jyt8',
  //       customer_identity_jwt: null,
  //     })
  //   })
  // }, [])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     // Load your script here
  //     const script = document.createElement('script')
  //     script.src = 'https://js.smile.io/v1/smile-ui.js'
  //     document.head.appendChild(script)
  //   }, 2000) // 5 seconds

  //   return () => clearTimeout(timer) // Cleanup on unmount
  // }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://acsbapp.com/apps/app/dist/js/app.js'
      script.onload = () => {
        if (window.acsbJS != undefined)
          window.acsbJS.init({
            statementLink: '',
            footerHtml: '',
            hideMobile: false,
            hideTrigger: false,
            disableBgProcess: false,
            language: 'en',
            position: 'left',
            leadColor: '#146FF8',
            triggerColor: '#146FF8',
            triggerRadius: '50%',
            triggerPositionX: 'left',
            triggerPositionY: 'bottom',
            triggerIcon: 'people',
            triggerSize: 'bottom',
            triggerOffsetX: 20,
            triggerOffsetY: 20,
            mobile: {
              triggerSize: 'medium',
              triggerPositionX: 'left',
              triggerPositionY: 'bottom',
              triggerOffsetX: 16,
              triggerOffsetY: 20,
              triggerRadius: '20',
            },
          })
      }
      document.head.appendChild(script)
    }, 2000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])

  
  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Lhr9tC&shop=penguincbd.myshopify.com'
      document.head.appendChild(script)
    }, 2000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://tools.luckyorange.com/core/lo.js?site-id=344f05cc'
      document.head.appendChild(script)
    }, 2000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])

  
  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://cdn.aimerce.ai/a.browser.shopify.hydrogen.umd.js'
      document.head.appendChild(script)
    }, 2000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])

  
  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://config.gorgias.chat/bundle-loader/01JCNHYTK68DY4VPD501NSKKWJ'
      document.head.appendChild(script)
    }, 5000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://www.dwin1.com/84735.js'
      document.head.appendChild(script)
    }, 2000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://penguincbd.refersion.com/tracker/v3/pub_dbf507f4c09c93d60f17.js'
      script.onload = () => {
        if (typeof window._refersion != 'undefined') window._refersion()
      }
      document.head.appendChild(script)
    }, 2000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      // Load your script here
      const script = document.createElement('script')
      script.src = 'https://shop.pe/widget/widget_async.js#63ca5f46dd02c504ba9f86bf'
      document.head.appendChild(script)
    }, 2000) // 5 seconds

    return () => clearTimeout(timer) // Cleanup on unmount
  }, [])


  useEffect(
    function listen() {
      const event = (...params) => {
        document
          .querySelector('#smile-ui-container')
          ?.classList.remove('over-fixed-form')
      }
      router.events.on('beforeHistoryChange', event)

      return () => {
        router.events.off('beforeHistoryChange', event)
      }
    },
    [router]
  )

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const discount = params.get('discount');
    if (discount) {
      localStorage.setItem('discountCode', discount);
      console.log("Discount Code Detected: ", discount);
    }
  }, []);

  const getLayout = Component.getLayout || ((page) => page)
  return (
    <>
      <NextTransitionBar showSpinner={false} color="#19B4EB" />
      <Head>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      {/* <Script
        key="smile.io"
        src="https://js.smile.io/v1/smile-ui.js"
        strategy="afterInteractive"
      /> */}
      <SessionProvider session={session}>
        <GlobalAppPropsProvider props={pageProps}>
          <UIProvider>
            <StoreProvider>
              <AccountProvider>
                <ThemeUIProvider theme={ThemeUi as any}>
                  {/* TODO SEO */}
                  <Toaster />
                  <style
                    dangerouslySetInnerHTML={{
                      // sets nice typography stuff for us, like spacing etc
                      __html: typographyStyle,
                    }}
                  />
                  <style
                    dangerouslySetInnerHTML={{
                      // necessary for modals like the cart to use the right font
                      __html: `body { font-family: ${sofia.style.fontFamily}; }`,
                    }}
                  />
                  <div
                    style={{
                      minHeight: '100vh',
                      fontFamily: 'var(--font-sofia)',
                      scrollBehavior: 'smooth',
                    }}
                    id="penguin_app"
                    className={[sofia.className, sofia.variable].join(' ')}
                  >
                    <Header
                      contentfulMainHeaderSettings={
                        pageProps.mainHeaderSettings
                      }
                      {...pageProps.headerProps}
                    />
                    <SmartNavDrawer
                      links={pageProps.mainHeaderSettings?.mobileLinkList}
                    />
                    <CartModal cartFreeShipping={cartFreeShipping} />
                    {getLayout(<Component {...restPageProps} />)}
                    <Footer />
                    <GoogleTagManager gtmId="GTM-PM5NQ6RM" />
                  </div>
                </ThemeUIProvider>
              </AccountProvider>
            </StoreProvider>
          </UIProvider>
        </GlobalAppPropsProvider>
      </SessionProvider>
    </>
  )
}
